var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0"},[(!_vm.myRoleSettings)?_c('fc-role-loading',{attrs:{"my-role-settings":_vm.myRoleSettings,"page-title":_vm.pageTitle}}):[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.pageTitle))]),_c('v-spacer'),_c('v-sheet',{staticClass:"ml-1",attrs:{"outlined":"","rounded":"","min-width":"200px"}},[_c('v-text-field',{attrs:{"label":"フリーワードで絞り込み","solo":"","flat":"","dense":"","hide-details":""},model:{value:(_vm.searchWord),callback:function ($$v) {_vm.searchWord=$$v},expression:"searchWord"}})],1),_c('fc-check-filter',{staticClass:"ml-1",attrs:{"options":_vm.status,"label":"ステータスで絞り込み"},model:{value:(_vm.selectedStatusValues),callback:function ($$v) {_vm.selectedStatusValues=$$v},expression:"selectedStatusValues"}}),(_vm.myRoleSettings.createAndUpdate)?_c('fc-import-file',{ref:"child",staticClass:"ml-1",attrs:{"isDisabled":_vm.isSelected,"onFileChange":_vm.importTsvFile,"acceptFormat":'text/tab-separated-values',"label":'TSV登録',"isLoading":_vm.isImporting}}):_vm._e(),(_vm.myRoleSettings.createAndUpdate)?_c('v-btn',{staticClass:"ml-1",attrs:{"elevation":"0","color":"primary","to":{
          name: 'CreatePlayList',
          params: {
            selectedMusics: _vm.paramPlaylist,
          },
        }}},[_vm._v("プレイリスト作成")]):_vm._e(),(_vm.myRoleSettings.createAndUpdate)?_c('v-btn',{staticClass:"ml-1",attrs:{"elevation":"0","color":"primary","to":"/musics/create","disabled":_vm.isSelected}},[_vm._v("新規作成")]):_vm._e()],1),(_vm.isImporting)?_c('div',{staticClass:"text-center pa-10"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey"}})],1):_c('div',[(_vm.myRoleSettings.createAndUpdate)?_c('v-switch',{staticClass:"pa-0 ma-0 px-3",attrs:{"label":"楽曲選択"},model:{value:(_vm.isSelected),callback:function ($$v) {_vm.isSelected=$$v},expression:"isSelected"}}):_vm._e(),_c('v-sheet',{attrs:{"outlined":"","rounded":""}},[_c('v-data-table',{staticClass:"fixed-header",attrs:{"headers":_vm.headers,"items":_vm.displayMusics,"loading":_vm.isLoading,"loader-height":"2","height":_vm.tableHeight,"item-key":"musicId","show-select":_vm.isSelected,"options":_vm.pagination,"footer-props":{
            'items-per-page-text': '行/ページ:',
            'items-per-page-options': [100, 200, 300],
          }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.streamStatus",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{class:{
                warning: item.streamStatus === 'before',
                primary: item.streamStatus === 'now',
                error: item.streamStatus === 'finish',
              },attrs:{"small":""}},[(item.streamStatus === 'before')?_c('span',[_vm._v("配信前")]):(item.streamStatus === 'now')?_c('span',[_vm._v("配信中")]):(item.streamStatus === 'finish')?_c('span',[_vm._v("配信終了")]):_vm._e()])]}},{key:"item.musicName",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.musicName))])]}},{key:"item.artistName",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.artistName))])]}},{key:"item.writerName",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.writerName))])]}},{key:"item.composerName",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.composerName))])]}},{key:"item.albumName",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.albumName))])]}},{key:"item.releaseDate.value",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.releaseDate.date))])]}},{key:"item.viewDate.value",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.viewDate.dateTime))])]}},{key:"item.distributionDate",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.distributionStartDate.dateTime)+" 〜 "+_vm._s(item.distributionEndDate.dateTime))])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(_vm.myRoleSettings.createAndUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","to":{
                    path: ("/musics/" + (item.musicId)),
                    params: {
                      musicId: item.musicId,
                    },
                  },"disabled":_vm.isSelected,"loading":_vm.isSaving}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("編集")])]):_vm._e(),(_vm.myRoleSettings.delete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","loading":_vm.isSaving,"disabled":_vm.isSelected},on:{"click":function($event){return _vm.deleteMusic(item.musicId)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("削除")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","href":("" + _vm.hostingUrl + (item.url)),"target":"_blank","rel":"noopener noreferrer","disabled":_vm.isSelected || !(item.streamStatus === 'now')}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])],1)]}}],null,true)},[_c('span',[_vm._v("FCページを表示")])])]}}],null,true),model:{value:(_vm.isSelectedMusics),callback:function ($$v) {_vm.isSelectedMusics=$$v},expression:"isSelectedMusics"}})],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }