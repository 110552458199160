import { DisplayDate } from '@/admin/util';
import { reactive } from '@vue/composition-api';
import {
  createMusic,
  getMusics,
  deleteMusic,
  getPlayLists,
  getMusic,
  getPlayList,
  deletePlaylist,
  editMusic,
  savePlayList,
  editPlayList,
} from '@/admin/music';

// プレイリスト登録・編集時に登録する楽曲Id
export interface SelectedMusic {
  musicId: string | undefined;
}

// 楽曲登録編集
export interface BasicMusics {
  musicId?: string; // 登録時はId不要
  vimeoId: string;
  musicName: string;
  artistName: string;
  writerName: string; // 作詞者は任意
  composerName: string; // 作曲者は任意
  albumName: string; // アルバム名は任意
  lyricsIds: string;
  releaseDate: DisplayDate; // 発売日は任意
  viewDate: DisplayDate;
  distributionStartDate: DisplayDate;
  distributionEndDate: DisplayDate; // 配信終了日は任意
}

// 表示用楽曲一覧
export interface DisplayMusics extends BasicMusics {
  streamStatus: string;
}

// プレイリスト登録編集
export interface BasicPlayList {
  playlistId?: string; // 登録時はId不要
  playlistType: string;
  playlistName: string;
  thumbnail: string;
  releaseDate: DisplayDate; // 発売日は任意
  viewDate: DisplayDate;
  distributionStartDate: DisplayDate;
  distributionEndDate: DisplayDate; // 配信終了日は任意
  musicIds: string[];
}

// プレイリスト一覧
export interface DisplayPlayLists extends BasicPlayList {
  streamStatus: string;
}

class Music {
  music: DisplayMusics = {
    musicId: undefined, // 登録時はリクエストパラメータにIdを含めないため初期値undefined
    musicName: '',
    artistName: '',
    writerName: '',
    composerName: '',
    vimeoId: '',
    lyricsIds: '',
    releaseDate: new DisplayDate(),
    albumName: '',
    viewDate: new DisplayDate(),
    distributionStartDate: new DisplayDate(),
    distributionEndDate: new DisplayDate(),
    streamStatus: '',
  };

  /**
   * 楽曲情報を取得し、メンバ変数に格納する
   *
   * @param musicId - 楽曲Id
   */
  getMusic = async (musicId: string) => {
    const result = await getMusic(musicId);
    this.music = {
      musicName: result.musicName,
      artistName: result.artistName,
      writerName: result.writerName,
      composerName: result.composerName,
      musicId: result.musicId,
      vimeoId: result.vimeoId,
      lyricsIds: result.lyricsIds.length >= 2 ? result.lyricsIds.join(',') : result.lyricsIds?.toString(),
      releaseDate: new DisplayDate(result.releaseDate),
      albumName: result.albumName?.length >= 2 ? result.albumName.join(',') : result.albumName?.toString(),
      viewDate: new DisplayDate(result.viewDate),
      distributionStartDate: new DisplayDate(result.distributionStartDate),
      distributionEndDate: new DisplayDate(result.distributionEndDate),
      streamStatus: result.streamStatus,
    };
  };

  /**
   * 楽曲の新規作成・編集により楽曲情報を格納する
   *
   * @param music - 作成・編集する楽曲情報
   * @param musicId - 編集する際の楽曲Id
   */
  saveMusic = async (music: BasicMusics, musicId?: string) => {
    // バリデートチェック
    if (
      !music.musicName ||
      !music.artistName ||
      !music.vimeoId ||
      !music.lyricsIds ||
      !music.viewDate.value ||
      !music.distributionStartDate.value
    ) {
      throw '必須項目が入力されていません';
    }
    if (
      music.distributionStartDate.value &&
      music.distributionEndDate.value &&
      music.distributionStartDate.value > music.distributionEndDate.value
    ) {
      throw '配信開始日が配信終了日より後になっています';
    }
    const musicProps = {
      musicName: music.musicName,
      artistName: music.artistName,
      writerName: music.writerName,
      composerName: music.composerName,
      musicId: music.musicId,
      vimeoId: music.vimeoId,
      lyricsIds: music.lyricsIds.includes(',') ? music.lyricsIds.split(',') : [music.lyricsIds],
      releaseDate: music.releaseDate.value,
      albumName: music.albumName?.includes(',') ? music.albumName.split(',') : [music.albumName],
      viewDate: music.viewDate.value,
      distributionStartDate: music.distributionStartDate.value,
      distributionEndDate: music.distributionEndDate.value,
    };
    if (!musicId) {
      await createMusic(musicProps);
    } else {
      await editMusic(musicProps, musicId);
    }
  };
}

export class Musics {
  // Musics.vueとPlayList.vueで呼ばれるため、作成されるインスタンスは一つにしておく
  static MusicsInstance: Musics;
  musics: DisplayMusics[] | undefined = undefined;

  constructor() {
    if (Musics.MusicsInstance) {
      return reactive(Musics.MusicsInstance);
    }
    Musics.MusicsInstance = this;
  }

  static getInstance() {
    if (!Musics.MusicsInstance) {
      Musics.MusicsInstance = new Musics();
    }
    return reactive(Musics.MusicsInstance);
  }

  /**
   * 楽曲一覧を取得し、メンバ変数に格納する
   */
  getMusics = async () => {
    const result = await getMusics();
    const data = result ? result : undefined;
    if (data) {
      this.musics = data.map((data) => {
        return {
          musicId: data.musicId,
          vimeoId: data.vimeoId,
          streamStatus: data.streamStatus,
          musicName: data.musicName,
          artistName: data.artistName,
          writerName: data.writerName,
          composerName: data.composerName,
          albumName: data.albumName?.length >= 2 ? data.albumName.join(',') : data.albumName?.toString(),
          lyricsIds: data.lyricsIds.length >= 2 ? data.lyricsIds.join(',') : data.lyricsIds?.toString(),
          releaseDate: new DisplayDate(data.releaseDate),
          viewDate: new DisplayDate(data.viewDate),
          distributionStartDate: new DisplayDate(data.distributionStartDate),
          distributionEndDate: new DisplayDate(data.distributionEndDate),
        };
      });
    }
  };

  /**
   * 楽曲を削除する
   *
   * @param musicId - 削除する楽曲Id
   */
  deleteMusic = async (musicId: string) => {
    await deleteMusic(musicId);
  };

  /**
   * TSVファイルにより楽曲の一括登録をする
   *
   * @remarks
   * 読み込んだファイルから1行ずつ登録処理を行う。
   * 途中で処理が失敗した場合は、その時点でエラーを出し登録処理を終了する。
   * バリデート処理については登録を開始する前に一括で確認する。
   *
   * @param musics - 登録する楽曲情報
   */
  saveMusicFromTSV = async (musics: BasicMusics[]) => {
    // バリデート処理
    for (let i = 0; i < musics.length; i++) {
      if (
        !musics[i].musicName ||
        !musics[i].artistName ||
        !musics[i].vimeoId ||
        !musics[i].lyricsIds ||
        !musics[i].viewDate ||
        !musics[i].distributionStartDate
      ) {
        throw `${i + 1}行目で必須項目が入力されていません`;
      }
    }
    // 登録処理
    for (let i = 0; i < musics.length; i++) {
      const musicProps = {
        musicName: musics[i].musicName,
        artistName: musics[i].artistName,
        writerName: musics[i].writerName,
        composerName: musics[i].composerName,
        vimeoId: musics[i].vimeoId,
        lyricsIds: musics[i].lyricsIds.includes(',') ? musics[i].lyricsIds.split(',') : [musics[i].lyricsIds],
        albumName: musics[i].albumName?.includes(',') ? musics[i].albumName.split(',') : [musics[i].albumName],
        viewDate: musics[i].viewDate.value,
        distributionStartDate: musics[i].distributionStartDate.value,
        distributionEndDate: musics[i].distributionEndDate.value,
        releaseDate: musics[i].releaseDate.value,
      };
      try {
        await createMusic(musicProps);
      } catch (e) {
        // 登録に失敗した場合、失敗した楽曲のindexに応じたエラー文を返却する
        throw `${i + 1}行目で登録が失敗しました。${i + 1}行目以降のみ再度登録してください。`;
      }
    }
  };
}

class PlayList {
  playlist: BasicPlayList = {
    playlistId: undefined, // 登録時はリクエストパラメータにIdを含めないため初期値undefined
    playlistType: '',
    playlistName: '',
    viewDate: new DisplayDate(),
    releaseDate: new DisplayDate(),
    distributionStartDate: new DisplayDate(),
    distributionEndDate: new DisplayDate(),
    musicIds: [],
    thumbnail: '',
  };

  playlists: DisplayPlayLists[] | undefined = undefined;

  /**
   * プレイリスト情報を取得し、メンバ変数に格納する
   *
   * @param playListId - プレイリストId
   */
  getPlayList = async (playListId: string) => {
    const result = await getPlayList(playListId);
    this.playlist = {
      playlistId: result.playlistId,
      playlistType: result.playlistType,
      playlistName: result.playlistName,
      viewDate: new DisplayDate(result.viewDate),
      releaseDate: new DisplayDate(result.releaseDate),
      distributionStartDate: new DisplayDate(result.distributionStartDate),
      distributionEndDate: new DisplayDate(result.distributionEndDate),
      musicIds: result.musicIds,
      thumbnail: result.thumbnail,
    };
  };

  /**
   * プレイリストを削除する
   *
   * @param playListId - 削除するプレイリストId
   */
  deletePlaylist = async (playListId: string) => {
    await deletePlaylist(playListId);
  };

  /**
   * プレイリスト一覧を取得し、メンバ変数に格納する
   */
  getPlayLists = async () => {
    const result = await getPlayLists();
    const data = result ? result : undefined;
    if (data) {
      this.playlists = data.map((data) => ({
        streamStatus: data.streamStatus,
        playlistId: data.playlistId,
        playlistType: data.playlistType,
        playlistName: data.playlistName,
        releaseDate: new DisplayDate(data.releaseDate),
        viewDate: new DisplayDate(data.viewDate),
        distributionStartDate: new DisplayDate(data.distributionStartDate),
        distributionEndDate: new DisplayDate(data.distributionEndDate),
        musicIds: data.musicIds,
        thumbnail: data.thumbnail,
      }));
    }
  };

  /**
   * プレイリストの新規作成・編集によりプレイリスト情報を保存する
   *
   * @param musicIds - 作成・編集するプレイリストに登録する楽曲Id
   */
  savePlayList = async (musicIds: string[]) => {
    if (
      !this.playlist.playlistType ||
      !this.playlist.playlistName ||
      !this.playlist.thumbnail ||
      !this.playlist.distributionStartDate
    ) {
      throw '必須項目が入力されていません';
    } else if (musicIds.length === 0) {
      throw '登録する楽曲情報を選択してください。';
    }
    const playlistProps = {
      playlistId: this.playlist.playlistId,
      playlistType: this.playlist.playlistType,
      playlistName: this.playlist.playlistName,
      viewDate: this.playlist.viewDate.value,
      releaseDate: this.playlist.releaseDate.value,
      distributionStartDate: this.playlist.distributionStartDate.value,
      distributionEndDate: this.playlist.distributionEndDate.value,
      musicIds: [] as string[], // プレイリスト情報と登録する楽曲情報は分けているため、引数playlistには楽曲一覧を含んでいない
      thumbnail: this.playlist.thumbnail,
    };
    // プレイリスト情報と登録する楽曲一覧を統合する(楽曲IDがないことはないが、実装上は空文字が含まれる場合があるため、空文字は除外する)
    playlistProps.musicIds = playlistProps.musicIds.concat(musicIds.filter((musicId) => musicId !== ''));
    if (!this.playlist.playlistId) {
      await savePlayList(playlistProps);
    } else {
      await editPlayList(playlistProps, this.playlist.playlistId);
    }
  };
}

export const useMusic = () => {
  const music = new Music();
  return reactive(music);
};

export const usePlayList = () => {
  const playList = new PlayList();
  return reactive(playList);
};
