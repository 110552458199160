
















import { defineComponent, ref, PropType } from '@vue/composition-api';

export interface HTMLElementEvent<T extends HTMLElement> extends Event {
  target: T;
}
export default defineComponent({
  props: {
    // ファイルを読み込んだ後の処理を定義した関数を受け取る
    onFileChange: {
      type: Function as PropType<inputFile>,
      required: true,
    },
    // ボタンに表示する文言を受け取る
    label: {
      type: String,
      required: true,
    },
    // CSV形式やTSV形式など受け入れるファイルのフォーマットを指定する
    acceptFormat: {
      type: String,
      required: false,
    },
    // インポート時以外で非活性にしたいタイミングを受け取る
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    // インポート中フラグ
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const fileInput = ref();

    /**
     * クリック時のイベント
     *
     * @param e - イベントオブジェクト
     */
    const click = (e: HTMLElementEvent<HTMLInputElement>) => {
      // バリデーションなどで登録に失敗し、同じファイルを再度選択した際にもインポートできるようにリセット
      e.target.value = '';
    };
    /**
     * ファイルインポートダイアログ表示
     */
    const onFocus = () => fileInput.value.click();
    /**
     * ファイルインポート時の処理
     */
    const onUploadFile = () => {
      const file: File = fileInput.value.files[0];
      if (!file) {
        return;
      }
      // 親コンポーネントで指定した処理を実施する
      props.onFileChange(file);
    };

    return { onFocus, fileInput, onUploadFile, click };
  },
});
