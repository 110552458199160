























































































































































































import { DisplayDate } from '@/admin/util';
import { computed, defineComponent, ref, watch, Ref } from '@vue/composition-api';
import { Musics, DisplayMusics, BasicMusics } from '@/composition/music';
import { useConfirm } from '@/composition/confirm';
import core from '@/admin/core';
import { useNotification } from '@/composition/notification';
import FcImportFile from '@/components/FcImportFile.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import FcCheckFilter from '@/components/FcCheckFilter.vue';

export default defineComponent({
  name: 'Musics',
  components: {
    FcImportFile,
    FcRoleLoading,
    FcRoleDeny,
    FcCheckFilter,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('musics'));
    const musics = Musics.getInstance();
    const { confirmDialog } = useConfirm();
    const notification = useNotification();

    const isLoading = ref(false); // ロード中フラグ
    const isSaving = ref(false); // DB更新中フラグ
    const isImporting = ref(false); // TSVファイル読み込み中フラグ
    const isSelected = ref(false); // 楽曲選択ON/OFFフラグ
    const hostingUrl = core.hostingUrl; // ホストURL
    const searchWord = ref(''); // 検索ワード
    const isSelectedMusics: Ref<DisplayMusics[]> = ref([]); // 選択中の楽曲一覧
    const selectedStatusValues: Ref<string[]> = ref([]); // 選択されているステータスタイプ
    // 画面表示用楽曲一覧
    const displayMusics = computed(() => {
      const displayMusics = musics.musics?.map((music) => ({ ...music, url: `/#/musics/${music.musicId}` }));
      return (
        displayMusics
          ?.filter(
            (music) =>
              // ステータスで絞り込み
              !selectedStatusValues.value.length ||
              (music.streamStatus && selectedStatusValues.value.includes(music.streamStatus))
          )
          // 検索キーワードで絞り込み
          .filter((music) => !searchWord.value || JSON.stringify(music).includes(searchWord.value))
      );
    });
    // プレイリスト追加用パラメータ
    const paramPlaylist = computed(() =>
      isSelectedMusics.value.map((isSelectedMusic: DisplayMusics) => ({
        musicId: isSelectedMusic.musicId,
      }))
    );
    // 一覧に表示する項目
    const headers = [
      { text: 'ステータス', value: 'streamStatus' },
      { text: '楽曲名', value: 'musicName' },
      { text: 'アーティスト名', value: 'artistName' },
      { text: '作詞者', value: 'writerName' },
      { text: '作曲者', value: 'composerName' },
      { text: 'アルバム', value: 'albumName' },
      { text: '発売日', value: 'releaseDate.value' }, // ソートするためにvalueはnumberにしておく
      { text: '表示日時', value: 'viewDate.value' }, // ソートするためにvalueはnumberにしておく
      { text: '配信日時', value: 'distributionDate', sortable: false },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];
    // ステータスのタイプ
    const status = [
      { text: '配信前', value: 'before' },
      { text: '配信中', value: 'now' },
      { text: '配信終了', value: 'finish' },
    ];
    // ページネーションデフォルト設定
    const pagination = {
      page: 1,
      itemsPerPage: 100,
    };
    const tableHeight = window.innerHeight - 163; // テーブル枠の高さ

    /**
     * インポートTSVファイル登録処理
     *
     * @param file - 選択されたファイル
     */
    const importTsvFile = async (file: File) => {
      isImporting.value = true;
      try {
        const tsvText = await file.text();
        // 改行コードによって分割方法を変える
        const brTsvText =
          !tsvText.includes('\r\n') && tsvText.includes('\n') ? tsvText.split('\n') : tsvText.split('\r\n');
        const dividedTsvText = brTsvText.map((row) => {
          if (row !== '') {
            // 一番最後の行にも改行コードが含まれている場合も対応できるように考慮しておく
            return row.split('\t');
          }
        });
        const tsvProperty: BasicMusics[] = dividedTsvText
          .map((cell) => {
            if (cell) {
              // 日付フォーマットバリデーション
              const checkViewDate = cell[0].match(
                /^\d{4}\/(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01]) (\d|0\d|1\d|2[0-3]):([0-5]\d):([0-5]\d)$/
              );
              const checkDistributionStartDate = cell[1].match(
                /^\d{4}\/(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01]) (\d|0\d|1\d|2[0-3]):([0-5]\d):([0-5]\d)$/
              );
              const checkDistributionEndDate =
                cell[2] === ''
                  ? cell[2]
                  : cell[2].match(
                      /^\d{4}\/(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01]) (\d|0\d|1\d|2[0-3]):([0-5]\d):([0-5]\d)$/
                    );
              const checkReleaseDate =
                cell[10] === '' ? cell[10] : cell[10].match(/^\d{4}\/(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01])$/);

              if (
                checkViewDate === null ||
                checkDistributionStartDate === null ||
                checkDistributionEndDate === null ||
                checkReleaseDate === null
              ) {
                throw '日付が有効ではありません。日付のフォーマットを確認してください';
              }

              const viewDate: number = Date.parse(cell[0]); // 表示日時
              const distributionStartDate: number = Date.parse(cell[1]); // 配信開始日時
              const distributionEndDate: number | undefined = cell[2] !== '' ? Date.parse(cell[2]) : undefined; // 配信終了日時
              const vimeoId: string = cell[3]; // vimeoId
              const lyricsIds: string = cell[4]; // 歌詞Id。TSVからは複数の歌詞IDを登録しないとのことで、複数の考慮なし
              const musicName: string = cell[7]; // 楽曲Id
              const artistName: string = cell[8]; // アーティスト名
              const albumName: string = cell[9]; // アルバム名。TSVからは複数のアルバム名を登録しないとのことで、複数の考慮なし
              const releaseDate: number | undefined = cell[10] !== '' ? Date.parse(cell[10]) : undefined; // 発売日
              const writerName: string = cell[23]; // 作詞者
              const composerName: string = cell[24]; // 作曲者

              // 配信日バリデートチェック
              if (distributionEndDate && distributionStartDate > distributionEndDate) {
                throw '配信開始日が配信終了日より後になっています';
              }

              return {
                musicName: musicName,
                artistName: artistName,
                writerName: writerName,
                composerName: composerName,
                vimeoId: vimeoId,
                lyricsIds: lyricsIds,
                releaseDate: new DisplayDate(releaseDate),
                albumName: albumName,
                viewDate: new DisplayDate(viewDate),
                distributionStartDate: new DisplayDate(distributionStartDate),
                distributionEndDate: new DisplayDate(distributionEndDate),
              } as BasicMusics;
            }
          })
          .filter((basicMusics): basicMusics is BasicMusics => typeof basicMusics !== 'undefined');

        if (!(await confirmDialog('登録しますか？'))) return;

        await musics.saveMusicFromTSV(tsvProperty);
        notification.notify('楽曲を一括登録しました');
      } catch (e) {
        notification.error(e);
      } finally {
        isImporting.value = false;
        // 途中で失敗した場合も、その時点までの状態で楽曲一覧を再取得する
        await musics.getMusics();
      }
    };
    /**
     * 楽曲削除処理
     *
     * @param musicId - 削除する楽曲Id
     */
    const deleteMusic = async (musicId: string) => {
      if (!(await confirmDialog('本当に削除しますか？\n削除したら元に戻せません'))) return;
      isSaving.value = true;
      try {
        await musics.deleteMusic(musicId);
        notification.notify('楽曲を削除しました');
        await musics.getMusics();
      } catch (e) {
        notification.error(e);
      } finally {
        isSaving.value = false;
      }
    };

    /**
     * 初期表示処理
     */
    const init = async () => {
      isLoading.value = true;
      try {
        await musics.getMusics();
      } catch (e) {
        notification.error(e);
      } finally {
        isLoading.value = false;
      }
    };

    init();

    watch(
      () => isSelected.value,
      () => {
        // 楽曲選択フラグがOFFになった時に選択中の楽曲をリセットする
        if (!isSelected.value && isSelectedMusics.value.length) {
          isSelectedMusics.value = [];
        }
      }
    );

    return {
      pageTitle: '楽曲一覧',
      myRoleSettings,
      isLoading,
      isSaving,
      isImporting,
      isSelected,
      hostingUrl,
      searchWord,
      musics,
      isSelectedMusics,
      selectedStatusValues,
      displayMusics,
      paramPlaylist,
      headers,
      pagination,
      status,
      tableHeight,
      importTsvFile,
      deleteMusic,
      DisplayDate,
    };
  },
});
