import core from '@/admin/core';

// 楽曲登録・編集APIリクエストパラメータ
export interface MusicRequest {
  musicId?: string; // 登録時はId不要
  musicName: string;
  artistName: string;
  writerName: string; // 作詞者は任意
  composerName: string; // 作曲者は任意
  vimeoId: string;
  lyricsIds: string[];
  releaseDate?: number; // 発売日は任意
  albumName: string[]; // アルバム名は任意
  viewDate?: number;
  distributionStartDate?: number;
  distributionEndDate?: number; // 配信終了日は任意
}

// 楽曲一覧APIレスポンス
export interface MusicsResponse extends MusicRequest {
  streamStatus: string;
}

// プレイリスト登録・編集APIリクエストパラメータ
export interface PlayListRequest {
  playlistId?: string; // 登録時はId不要
  playlistType: string;
  playlistName: string;
  thumbnail: string;
  releaseDate?: number; // 発売日は任意
  viewDate?: number;
  distributionStartDate?: number;
  distributionEndDate?: number; // 配信終了日は任意
  musicIds: string[];
}

// プレイリスト一覧APIレスポンス
export interface PlayListsResponse extends PlayListRequest {
  streamStatus: string;
}

/**
 * 楽曲登録API
 *
 * @param music - 登録する楽曲情報
 */
export const createMusic = async (music: MusicRequest) => {
  await core.httpClient.post('/admin/public/musics', music);
};

/**
 * 楽曲編集API
 *
 * @param music - 編集する楽曲情報
 */
export const editMusic = async (music: MusicRequest, musicId: string) => {
  await core.httpClient.put(`/admin/public/musics/${musicId}`, music);
};

/**
 * 楽曲一件取得API
 *
 * @returns 楽曲
 */
export const getMusic = async (musicId: string) => {
  const result = await core.httpClient.get(`/admin/public/musics/${musicId}`);
  return result.data as MusicsResponse;
};

/**
 * 楽曲一覧取得API
 *
 * @returns 楽曲一覧
 */
export const getMusics = async () => {
  const result = await core.httpClient.get('/admin/public/musics');
  return result.data as MusicsResponse[];
};

/**
 * 楽曲削除API
 *
 * @param musicId - 削除する楽曲ID
 */
export const deleteMusic = async (musicId: string) => {
  await core.httpClient.delete(`/admin/public/musics/${musicId}`);
};

/**
 * プレイリスト登録API
 *
 * @param playlist - 登録するプレイリスト情報
 */
export const savePlayList = async (playlist: PlayListRequest) => {
  await core.httpClient.post('/admin/public/musics/playlists', playlist);
};

/**
 * プレイリスト編集API
 *
 * @param playlist - 編集するプレイリスト情報
 */
export const editPlayList = async (playlist: PlayListRequest, playlistId: string) => {
  await core.httpClient.put(`/admin/public/musics/playlists/${playlistId}`, playlist);
};

/**
 * プレイリスト一件取得API
 *
 * @returns プレイリスト
 */
export const getPlayList = async (playListId: string) => {
  const result = await core.httpClient.get(`/admin/public/musics/playlists/${playListId}`);
  return result.data as PlayListsResponse;
};

/**
 * プレイリスト一覧取得API
 *
 * @returns プレイリスト一覧
 */
export const getPlayLists = async () => {
  const result = await core.httpClient.get('/admin/public/musics/playlists');
  return result.data as PlayListsResponse[];
};

/**
 * プレイリスト削除API
 *
 * @param playListId - 削除するプレイリストID
 */
export const deletePlaylist = async (playListId: string) => {
  await core.httpClient.delete(`/admin/public/musics/playlists/${playListId}`);
};
